import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TranslationService } from './core/services/internal/translation.service';
import { LanguageService } from './core/services/internal/language.service';
import { TranslateModule } from '@ngx-translate/core';
import { LayoutModule } from './layout/layout.module';
import { FormsModule } from '@angular/forms';
import { HttpUtilsService } from './core/services/internal/http-utils.service';
import { ApiService } from './core/services/network/api.service';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

@NgModule({
	declarations: [
		AppComponent
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		TranslateModule.forRoot(),
		LayoutModule,
		FormsModule,
	],
	providers: [
		TranslationService,
		LanguageService,
		HttpUtilsService,
		ApiService,
		provideHttpClient(withInterceptorsFromDi())
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
