import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AngularDeviceInformationService } from 'angular-device-information';
import { Helpers } from 'src/app/core/helpers';
import { Device } from 'src/app/core/models/device.enum';

@Component({
	selector: 'app-instructions',
	templateUrl: './instructions.component.html',
	styleUrl: './instructions.component.less'
})
export class InstructionsComponent implements OnInit {
	@Input() deviceOs?: string;

	device = Device;

	constructor(private route: ActivatedRoute, private deviceInformationService: AngularDeviceInformationService) { }

	ngOnInit(): void {
        this.deviceOs = this.route.snapshot.params['deviceOs'] || this.deviceOs;

        if (!this.deviceOs) {
            this.deviceOs = Helpers.getDeviceOs(this.deviceInformationService.getDeviceInfo()?.os || '');
        }
	}
}
