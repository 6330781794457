export const locale = {
	lang: 'en',
	data: {
		languageSelector: {
			selectLanguage: 'Select Language'
		},
		accessibility: {
			resetTitle: 'reset',
			closeTitle: 'close',
			menuTitle: 'Accessibility Options',
			increaseText: 'increase text size',
			decreaseText: 'decrease text size',
			increaseTextSpacing: 'increase text spacing',
			decreaseTextSpacing: 'decrease text spacing',
			increaseLineHeight: 'increase line height',
			decreaseLineHeight: 'decrease line height',
			invertColors: 'invert colors',
			grayHues: 'gray hues',
			underlineLinks: 'underline links',
			bigCursor: 'big cursor',
			readingGuide: 'reading guide',
			textToSpeech: 'text to speech',
			speechToText: 'speech to text',
			disableAnimations: 'disable animations',
			hotkeyPrefix: 'Hotkey:'
		},
		pageNotFound: {
			title: 'Page Not Found',
			subtitle: 'Sorry, but the page you were trying to view does not exist.',
			action: 'Go to homepage'
		},
		error: {
			title: 'Error',
			subtitle: 'Sorry, but something went wrong.',
			action: 'Go to homepage',
			messages: {
				generic: 'An error occurred while processing your request. Please try again later.'
			}
		},
		home: {
			title: 'Welcome',
			downloadProfile: 'Download Your Account Wi-Fi Profile',
			email: {
				description: "If you want, you can provide your email address to receive all the instructions.",
				placeholder: "example@domain.com",
			},
			privacy: {
				one: 'I have read and understood',
				two: 'the Privacy Policy',
				three: 'and the',
				four: 'Terms and Conditions of the service',
				total:`I have read and understood <a class='font-source_regular text-text-blue' href="https://www.guglielmo.biz/en/privacy-cookies-en/" target="_blank" data-extlink rel="noreferrer">the Privacy Policy</a> and the <a class='font-source_regular text-text-blue' href="https://www.guglielmo.biz/en/legal-notes/" target="_blank" data-extlink rel="noreferrer">Terms and Conditions of the service</a>`
			},
			requiredFields: "Required fields are marked with an asterisk (*)",
			error: {
				emailFormat: "Invalid email format",
			}
		},
		instructions:{
			title:"Connect your device",
			checkOS: "Your Operating System is: ",
			changeOS:"Is this not correct? You can change it from here.",
			noOs: "Could not identify your Operating System",
			ios:{
				welcome_step1:`Click on the <span class='font-source_regular text-text-blue'>"Download Your Account Wi-Fi Profile"</span> button below to download your Wi-Fi Profile and click <span class='font-source_regular text-text-blue'>"Continue"</span> on the window that appears`,
				welcome_step2:`Click <span class='font-source_regular text-text-blue'>"Allow"</span> on the screen that appears`,
				welcome_step3:`Click <span class='font-source_regular text-text-blue'>"Close"</span> on the screen that appears`,
				welcome_step4:`Once the download is complete, you will be redirected to the page with instructions to install your Wi-Fi Profile.`,
			},
			android:{
				welcome_step1:`Click on the <span class='font-source_regular text-text-blue'>"Download Your Account Wi-Fi Profile"</span> button below to download your Wi-Fi Profile and click <span class='font-source_regular text-text-blue'>"Continue"</span> on the window that appears`
			},
			mac: {
				welcome_step1: `Click on the button below <span class='font-source_regular text-text-blue'>"Download your Wi-Fi profile"</span> to download the Wi-Fi profile`,
			},

		},
		install_instructions:{
			title:"How to install your Wi-Fi Account Profile",
			ios:{
				install_step1: `Open the <span class='font-source_regular text-text-blue'>"Settings"</span> app and click on <span class='font-source_regular text-text-blue'>"Downloaded Profile"</span>`,
				install_step2: `Click on <span class='font-source_regular text-text-blue'>"Install"</span> in the top-right corner and enter your device's passcode`,
				install_step3: `Click on <span class='font-source_regular text-text-blue'>"Install"</span> in the window that appears at the bottom to confirm the installation`,
				install_step4: `After installing your Wi-Fi account profile, click on <span class='font-source_regular text-text-blue'>"Done"</span>. You will see that your profile has been successfully installed in the <span class='font-source_regular text-text-blue'>"VPN & Device Management"</span> section`,
				install_step5: `It is now possible to search for the Wi-Fi network name and access it securely`,
			},
			android:{
				install_step1: `Click on <span class='font-source_regular text-text-blue'>"Done"</span> in the window that appears below to confirm the installation`,
				install_step2: `You can now search for the Wi-Fi network name and securely connect`,
			},
			mac: {
				install_step1: `Click on the downloaded file, open the <span class='font-source_regular text-text-blue'>"Settings"</span> app, and click on <span class='font-source_regular text-text-blue'>"Privacy & Security"</span>`,
				install_step2: `Click on <span class='font-source_regular text-text-blue'>"Profile"</span>`,
				install_step3: `Double click on the profile, then click on <span class='font-source_regular text-text-blue'>"Install"</span>`,
				install_step4: `If prompted, enter your personal password and click <span class='font-source_regular text-text-blue'>"OK"</span>`,
				install_step5: `You can now search for the Wi-Fi network name and securely connect`,
			},

		},
		footer:{
			privacyPolicy:`<a class='font-source_regular text-white hover:text-gray-400' href="https://www.guglielmo.biz/en/privacy-cookies-en/" target="_blank" data-extlink rel="noreferrer">Privacy Policy</a>`,
			termsAndConditions:`<a class='font-source_regular text-white hover:text-gray-400' href="https://www.guglielmo.biz/en/legal-notes/" target="_blank" data-extlink rel="noreferrer">Terms and Conditions of the service</a>`,
		}
	}
}