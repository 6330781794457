export const locale = {
	lang: 'pt',
	data: {
		languageSelector: {
			selectLanguage: 'Selecione o Idioma'
		},
		accessibility: {
			resetTitle: 'redefinir',
			closeTitle: 'fechar',
			menuTitle: 'Opções de Acessibilidade',
			increaseText: 'aumentar tamanho do texto',
			decreaseText: 'diminuir tamanho do texto',
			increaseTextSpacing: 'aumentar espaçamento do texto',
			decreaseTextSpacing: 'diminuir espaçamento do texto',
			increaseLineHeight: 'aumentar altura da linha',
			decreaseLineHeight: 'diminuir altura da linha',
			invertColors: 'inverter cores',
			grayHues: 'tons de cinza',
			underlineLinks: 'sublinhar links',
			bigCursor: 'cursor grande',
			readingGuide: 'guia de leitura',
			textToSpeech: 'texto para fala',
			speechToText: 'fala para texto',
			disableAnimations: 'desativar animações',
			hotkeyPrefix: 'Atalho:'
		},
		pageNotFound: {
			title: 'Página não encontrada',
			subtitle: 'Desculpe, mas a página que você estava tentando visualizar não existe.',
			action: 'Ir para a página inicial'
		},
		error: {
			title: 'Erro',
			subtitle: 'Desculpe, mas algo deu errado.',
			action: 'Ir para a página inicial',
			messages: {
				generic: 'Ocorreu um erro'
			}
		},
		home: {
			title: 'Bem-vindo',
			downloadProfile: 'Baixar seu perfil Wi-Fi',
			email: {
				description: "Se desejar, você pode fornecer seu endereço de e-mail para receber todas as instruções.",
				placeholder: "exemplo@example.com.br",
			},
			privacy: {
				one: 'Li e compreendi',
				two: 'a Política de Privacidade',
				three: 'e as',
				four: 'Condições do Serviço',
				total:`Li e compreendi <a class='font-source_regular text-text-blue' href="https://www.guglielmo.biz/pt/politica-de-privacidade/" target="_blank" data-extlink rel="noreferrer">a Política de Privacidade</a> e as <a class='font-source_regular text-text-blue' href="https://www.guglielmo.biz/pt/aviso-legal/" target="_blank" data-extlink rel="noreferrer">Condições do Serviço</a>`
			},
			requiredFields: "Campos obrigatórios são marcados com um asterisco (*)",
			error: {
				emailFormat: "Formato de email inválido",
			}
		},
		instructions:{
			title:"Conecte seu dispositivo",
			checkOS: "Seu Sistema Operacional é: ",
			changeOS:"Isso não está correto? Você pode alterá-lo daqui",
			noOs: "Não foi possível identificar o seu Sistema Operacional",
			ios:{
				welcome_step1:`Clique no botão abaixo<span class='font-source_regular text-text-blue'>"Baixar seu perfil Wi-Fi"</span> para baixar o perfil Wi-Fi e clique em <span class='font-source_regular text-text-blue'>"Continuar"</span> na janela que aparecer`,
				welcome_step2:`Clique em <span class='font-source_regular text-text-blue'>"Aceitar"</span> na tela que aparecer`,
				welcome_step3:`Clique em <span class='font-source_regular text-text-blue'>"Fechar"</span> na tela que aparecer`,
				welcome_step4:"Assim que o download for concluído, você será redirecionado para a página com instruções para instalar seu perfil Wi-Fi.",
			},
			android:{
				welcome_step1:`Para baixar o seu perfil de Wi-Fi, clique no botão <span class='font-source_regular text-text-blue'>\"Baixar seu perfil de Wi-Fi\"</span> localizado na parte inferior da página de download e pressione \"Instalar\" na janela que aparece em seguida.`,
			},
			mac: {
				welcome_step1: `Clique no botão abaixo <span class='font-source_regular text-text-blue'>"Baixar seu perfil Wi-Fi"</span> para baixar o perfil Wi-Fi`,
				welcome_step1_how_to:`Para baixar o seu perfil de Wi-Fi, clique no botão <span class='font-source_regular text-text-blue'>"Baixar seu perfil Wi-Fi"</span> localizado na parte inferior da página de download`,
			},
			windows:{
				welcome_step1_how_to:`Para baixar o seu perfil de Wi-Fi, clique no botão <span class='font-source_regular text-text-blue'>"Baixar seu perfil de Wi-Fi"</span> localizado na parte inferior da página de download`,
				welcome_step1:`Para baixar o seu perfil de Wi-Fi, clique no botão <span class='font-source_regular text-text-blue'>"Baixar seu perfil de Wi-Fi"</span> localizado na parte inferior da página de download`,
			},
		},
		install_instructions:{
			title:"Como instalar seu perfil de conta Wi-Fi",
			ios:{
				install_step1: `Abra o aplicativo <span class='font-source_regular text-text-blue'>"Configurações"</span> e clique em <span class='font-source_regular text-text-blue'>"Perfil Baixado"</span>`,
				install_step2: `Clique em <span class='font-source_regular text-text-blue'>"Instalar"</span> no canto superior direito e insira o código de desbloqueio do seu dispositivo`,
				install_step3: `Clique em <span class='font-source_regular text-text-blue'>"Instalar"</span> na janela que aparece abaixo para confirmar a instalação`,
				install_step4: `Depois de instalar o perfil da sua conta Wi-Fi, clique em <span class='font-source_regular text-text-blue'>"Concluir"</span>. Você verá que o seu perfil foi instalado corretamente na seção <span class='font-source_regular text-text-blue'>"VPN e gerenciamento de dispositivos"</span>`,
				install_step5: `Agora é possível procurar o nome da rede Wi-Fi e acessar com segurança`,
			},
			android:{
				install_step1: `Clique em <span class='font-source_regular text-text-blue'>"Concluído"</span> na janela que aparece abaixo para confirmar a instalação`,
				install_step2: `Agora você pode procurar o nome da rede Wi-Fi e conectar-se com segurança`,
			},
			mac: {
				install_step1: `Clique no arquivo baixado, abra o aplicativo <span class='font-source_regular text-text-blue'>"Configurações"</span> e clique em <span class='font-source_regular text-text-blue'>"Privacidade e Segurança"</span>`,
				install_step2: `Clique em <span class='font-source_regular text-text-blue'>"Perfil"</span>`,
				install_step3: `Dê um duplo clique no perfil e depois clique em <span class='font-source_regular text-text-blue'>"Instalar"</span>`,
				install_step4: `Se solicitado, insira sua senha pessoal e clique em <span class='font-source_regular text-text-blue'>"OK"</span>`,
				install_step5: `Agora você pode procurar o nome da rede Wi-Fi e conectar-se com segurança`,
			},
			windows:{
				install_step1:`Clique em <span class='font-source_regular text-text-blue'>Abrir Configurações</span> na janela que aparece`,
				install_step2:`Clique em <span class='font-source_regular text-text-blue'>Adicionar</span> na janela que aparece`,
				install_step3:`Agora você pode procurar o nome da rede Wi-Fi e conectar-se com segurança`,
			},
		},
		landing:{
			text_1: 'OpenRoaming é um padrão desenvolvido pela Wireless Broadband Alliance (WBA) projetado para simplificar o processo de conexão a redes Wi-Fi, criando uma experiência de roaming contínua e segura.',
			text_2: 'O projeto visa criar uma experiência de roaming contínuo entre as diversas redes Wi-Fi dispersas por Londres.',
			text_3: 'Você pode se conectar a essas redes Wi-Fi sem precisar fazer login repetidamente ou inserir credenciais, protegendo seus dados e privacidade.',
			text_4: 'Tudo o que você precisa fazer é baixar um "perfil de Wi-Fi" válido no seu dispositivo e aproveitar.',
			connect_btn: 'Conectar agora',
			connect_howTo: 'Como fazer',
			text_5: 'Precisa de mais detalhes? Descubra como funciona!',
		},
		how_to:{
			intro:`Para se conectar às redes Wi-Fi, você precisará baixar um "perfil Wi-Fi" no seu dispositivo e aproveitar a navegação.`,
			title_1:`Como baixar seu perfil`,
			title_2:`Como instalar seu perfil Wi-Fi`,
		},
		footer:{
			privacyPolicy:`<a class='font-source_regular text-white hover:text-gray-400' href="https://wballiance.com/openroaming/privacy-policy/" target="_blank" data-extlink rel="noreferrer">Política de Privacidade</a>`,
			termsAndConditions:`<a class='font-source_regular text-white hover:text-gray-400' href="https://wballiance.com/openroaming/toc/" target="_blank" data-extlink rel="noreferrer">Condições do Serviço</a>`,
		}
	}
}