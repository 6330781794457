import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'app-checkbox',
	templateUrl: './checkbox.component.html',
	styleUrl: './checkbox.component.less'
})
export class CheckboxComponent {
	@Input() label: string = 'Checkbox Label';
	@Input() checked: boolean = false;
	@Output() checkedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

	onCheckboxChange() {
		this.checkedChange.emit(this.checked);
	}
}
