import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Accessibility } from 'accessibility';
import { Subscription } from 'rxjs';
import { Helpers } from 'src/app/core/helpers';
import { LanguageService } from 'src/app/core/services/internal/language.service';

@Component({
	selector: 'app-accessibility',
	templateUrl: './accessibility.component.html',
	styleUrl: './accessibility.component.less'
})
export class AccessibilityComponent implements OnInit, AfterViewInit, OnDestroy {
	subscriptions: Array<Subscription> = [];
	accessibility: Accessibility | null = null;
	isInitialized = false;

	constructor(private languageService: LanguageService, private translateService: TranslateService) { }

	ngOnInit(): void {
		this.subscriptions.push(
			this.languageService.language$.subscribe(() => {
					if (this.accessibility !== null) {
						this.accessibility.destroy();
						this.clearLocalStorage();
						setTimeout(() => {
							this.accessibility = null;
							this.initializeAccessibility();
						}, 100);
					} else {
						this.initializeAccessibility();
					}
			})
		);
	}

	initializeAccessibility(): void {
		this.setLocalStorage(
			() => {
				// Accessibility options
				const labels = {
					resetTitle: this.translateService.instant('accessibility.resetTitle'),
					closeTitle: this.translateService.instant('accessibility.closeTitle'),
					menuTitle: this.translateService.instant('accessibility.menuTitle'),
					increaseText: this.translateService.instant('accessibility.increaseText'),
					decreaseText: this.translateService.instant('accessibility.decreaseText'),
					increaseTextSpacing: this.translateService.instant('accessibility.increaseTextSpacing'),
					decreaseTextSpacing: this.translateService.instant('accessibility.decreaseTextSpacing'),
					increaseLineHeight: this.translateService.instant('accessibility.increaseLineHeight'),
					decreaseLineHeight: this.translateService.instant('accessibility.decreaseLineHeight'),
					invertColors: this.translateService.instant('accessibility.invertColors'),
					grayHues: this.translateService.instant('accessibility.grayHues'),
					underlineLinks: this.translateService.instant('accessibility.underlineLinks'),
					bigCursor: this.translateService.instant('accessibility.bigCursor'),
					readingGuide: this.translateService.instant('accessibility.readingGuide'),
					textToSpeech: this.translateService.instant('accessibility.textToSpeech'),
					speechToText: this.translateService.instant('accessibility.speechToText'),
					disableAnimations: this.translateService.instant('accessibility.disableAnimations'),
					hotkeyPrefix: this.translateService.instant('accessibility.hotkeyPrefix'),
				};
				const modules = {
					increaseText: true,
					decreaseText: true,
					invertColors: true,
					increaseTextSpacing: true,
					decreaseTextSpacing: true,
					increaseLineHeight: true,
					decreaseLineHeight: true,
					grayHues: true,
					underlineLinks: true,
					bigCursor: true,
					readingGuide: true,
					textToSpeech: false,
					speechToText: false,
					disableAnimations: true
				};
				const options = {
					labels: labels,
					language: {
						textToSpeechLang: 'en-US',
						speechToTextLang: 'en-US'
					},
					modules: modules,
					/* icon: {
						img: 'accessible',
					} */
				};
				// Initialize Accessibility
				this.accessibility = new Accessibility(options);
				setTimeout(() => {
					this.buLocalStorage();
				}, 2000);
			}
		);
	}

	ngAfterViewInit(): void {
		// Add custom CSS
		const style = document.createElement('style');
		style.innerHTML = `
			body {
			  --_access-icon-border-radius: 50%;
			  --_access-icon-border: .5px solid white;
			}
		  `;
		document.body.appendChild(style);
	}

	clearLocalStorage(): void {
		const data = localStorage.getItem('_accessState')!;
		console.log('Clear storage', data);
		Helpers.createCookie('_accessState_bu', data, 1);
		// localStorage.removeItem('_accessState');
	}

	setLocalStorage(callback: any): void {
		localStorage.setItem('_accessState', "");
		//Helpers.deleteCookie('_accessState_bu');
		setTimeout(() => {
			localStorage.setItem('_accessState', Helpers.getCookie('_accessState_bu')!);
			callback();
		}, 1000);
	}

	buLocalStorage(): void {
		const data = localStorage.getItem('_accessState')!;
		console.log('bu data', data);
		Helpers.createCookie('_accessState_bu', data, 1);
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(subscription => (subscription.unsubscribe()));
		this.accessibility?.destroy();
		this.accessibility = null;
		this.clearLocalStorage();
	}

}
