export const locale = {
	lang: 'de',
	data: {
		languageSelector: {
			selectLanguage: 'Sprache auswählen'
		},
		accessibility: {
			resetTitle: 'Zurücksetzen',
			closeTitle: 'Schließen',
			menuTitle: 'Optionen für die Zugänglichkeit',
			increaseText: 'Textgröße vergrößern',
			decreaseText: 'Textgröße verkleinern',
			increaseTextSpacing: 'Zeilenabstand vergrößern',
			decreaseTextSpacing: 'Zeilenabstand verkleinern',
			increaseLineHeight: 'Linienhöhe vergrößern',
			decreaseLineHeight: 'Linienhöhe verkleinern',
			invertColors: 'Farben umkehren',
			grayHues: 'Grautöne',
			underlineLinks: 'Hyperlinks unterstreichen',
			bigCursor: 'Großer Cursor',
			readingGuide: 'Lesegurtel',
			textToSpeech: 'Text in Sprache übersetzen',
			speechToText: 'Sprache in Text übersetzen',
			disableAnimations: 'Animationen deaktivieren',
			hotkeyPrefix: 'Hotkey:'
		},
		pageNotFound: {
			title: 'Seite nicht gefunden',
			subtitle: 'Entschuldigung, aber die Seite, die Sie anzeigen wollten, existiert nicht.',
			action: 'Zur Startseite'
		},
		error: {
			title: 'Fehler',
			subtitle: 'Entschuldigung, aber etwas ist schief gelaufen.',
			action: 'Zur Startseite',
			messages: {
				generic: 'Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.'
			}
		},
		home: {
			title: 'Willkommen',
			downloadProfile: 'Laden Sie Ihr Wi-Fi-Profil herunter',
			email: {
				description: "Wenn Sie möchten, können Sie Ihre E-Mail-Adresse angeben, um alle Anweisungen zu erhalten.",
				placeholder: "beispiel@example.de",
			},
			privacy: {
				one: 'Ich habe gelesen und verstanden',
				two: 'die Datenschutzerklärung',
				three: 'und die',
				four: 'Nutzungsbedingungen des Dienstes',
				total:`Ich habe gelesen und verstanden <a class='font-source_regular text-text-blue' href="https://www.guglielmo.biz/de/datenschutz/" target="_blank" data-extlink rel="noreferrer">die Datenschutzerklärung</a> und die <a class='font-source_regular text-text-blue' href="https://www.guglielmo.biz/de/rechtliche-hinweise/" target="_blank" data-extlink rel="noreferrer">Nutzungsbedingungen des Dienstes</a>`,
			},
			requiredFields: "Pflichtfelder sind mit einem Stern (*) markiert",
			error: {
				emailFormat: "Ungültiges E-Mail-Format",
			}
		},
		instructions:{
			title:"Verbinden Sie Ihr Gerät",
			checkOS: "Ihr Betriebssystem ist: ",
			changeOS:"Ist das nicht korrekt? Sie können es hier ä",
			noOs: "Ihr Betriebssystem konnte nicht identifiziert werden",
			ios:{
				welcome_step1:`Klicken Sie auf die Schaltfläche unten <span class='font-source_regular text-text-blue'>"Laden Sie Ihr Wi-Fi-Profil herunter"</span>, um das Wi-Fi-Profil herunterzuladen, und klicken Sie auf <span class='font-source_regular text-text-blue'>"Weiter"</span> im erscheinenden Fenster`,
				welcome_step2:`Klicken Sie auf <span class='font-source_regular text-text-blue'>"Akzeptieren"</span> auf dem Bildschirm, der erscheint`,
				welcome_step3:`Klicken Sie auf <span class='font-source_regular text-text-blue'>"Schließen"</span> auf dem Bildschirm, der erscheint`,
				welcome_step4:`Sobald der Download abgeschlossen ist, werden Sie auf die Seite mit den Anweisungen zur Installation Ihres WLAN-Profils weitergeleitet.`,
			},
			android:{
				welcome_step1:`Um dein WLAN-Profil herunterzuladen, klicke auf die Schaltfläche <span class='font-source_regular text-text-blue'>„Lade dein WLAN-Profil herunter“</span>, die unten auf der Download-Seite erscheint, und klicke anschließend im erscheinenden Fenster auf „Installieren“.`,
			},
			mac: {
				welcome_step1: `Klicke auf die Schaltfläche unten <span class='font-source_regular text-text-blue'>"Lade dein WLAN-Profil herunter"</span>, um das WLAN-Profil herunterzuladen`,
				welcome_step1_how_to:`Um dein WLAN-Profil herunterzuladen, klicke auf die Schaltfläche <span class='font-source_regular text-text-blue'>"Lade dein WLAN-Profil herunter"</span>, die unten auf der Download-Seite erscheint`,
			},
			windows:{
				welcome_step1_how_to:`Um dein WLAN-Profil herunterzuladen, klicke auf die Schaltfläche <span class='font-source_regular text-text-blue'>"Lade dein WLAN-Profil herunter"</span>, die unten auf der Download-Seite erscheint`,
				welcome_step1:`Um dein WLAN-Profil herunterzuladen, klicke auf die Schaltfläche <span class='font-source_regular text-text-blue'>"Lade dein WLAN-Profil herunter"</span>, die unten auf der Download-Seite erscheint`,
			},

		},
		install_instructions:{
			title:"So installieren Sie Ihr Wi-Fi-Konto-Profil",
			ios:{
				install_step1: `Öffnen Sie die App <span class='font-source_regular text-text-blue'>"Einstellungen"</span> und klicken Sie auf <span class='font-source_regular text-text-blue'>"Heruntergeladenes Profil"</span>`,
				install_step2: `Klicken Sie auf <span class='font-source_regular text-text-blue'>"Installieren"</span> in der oberen rechten Ecke und geben Sie den Entsperrcode Ihres Geräts ein`,
				install_step3: `Klicken Sie auf <span class='font-source_regular text-text-blue'>"Installieren"</span> im Fenster, das unten erscheint, um die Installation zu bestätigen`,
				install_step4: `Nachdem Sie das Profil Ihres Wi-Fi-Kontos installiert haben, klicken Sie auf <span class='font-source_regular text-text-blue'>"Fertig"</span>. Sie werden sehen, dass Ihr Profil korrekt in der <span class='font-source_regular text-text-blue'>"VPN- und Geräteverwaltung"</span> installiert wurde`,
				install_step5: `Es ist nun möglich, den Namen des Wi-Fi-Netzwerks zu suchen und sicher zuzugreifen`,
			},
			android:{
				install_step1: `Klicke auf <span class='font-source_regular text-text-blue'>"Fertig"</span> im Fenster, das unten erscheint, um die Installation zu bestätigen`,
				install_step2: `Du kannst jetzt den Namen des WLAN-Netzwerks suchen und sicher darauf zugreifen`,
			},
			mac: {
				install_step1: `Klicke auf die heruntergeladene Datei, öffne die <span class='font-source_regular text-text-blue'>"Einstellungen"</span> App und klicke auf <span class='font-source_regular text-text-blue'>"Datenschutz & Sicherheit"</span>`,
				install_step2: `Klicke auf <span class='font-source_regular text-text-blue'>"Profil"</span>`,
				install_step3: `Doppelklicke auf das Profil und dann auf <span class='font-source_regular text-text-blue'>"Installieren"</span>`,
				install_step4: `Falls erforderlich, gib dein persönliches Passwort ein und klicke auf <span class='font-source_regular text-text-blue'>"OK"</span>`,
				install_step5: `Du kannst jetzt den Namen des WLAN-Netzwerks suchen und sicher darauf zugreifen`,
			},
			windows:{
				install_step1:`Klicke auf <span class='font-source_regular text-text-blue'>Einstellungen öffnen</span> im erscheinenden Fenster`,
				install_step2:`Klicke auf <span class='font-source_regular text-text-blue'>Hinzufügen</span> im erscheinenden Fenster`,
				install_step3:`Nun kannst du nach dem Namen des WLAN-Netzwerkes suchen und dich sicher verbinden`,
			},
		},
		landing:{
			text_1: 'OpenRoaming ist ein von der Wireless Broadband Alliance (WBA) entwickelter Standard, der den Prozess der Verbindung mit Wi-Fi-Netzwerken durch die Schaffung eines nahtlosen und sicheren Roaming-Erlebnisses vereinfacht.',
			text_2: 'Das Projekt zielt darauf ab, ein ununterbrochenes Roaming-Erlebnis in den vielen verschiedenen Wi-Fi-Netzwerken in London zu schaffen.',
			text_3: 'Sie können sich mit diesen Wi-Fi-Netzwerken verbinden, ohne sich wiederholt einloggen oder Anmeldedaten eingeben zu müssen, und schützen dabei Ihre Daten und Privatsphäre.',
			text_4: 'Alles, was Sie tun müssen, ist ein gültiges "Wi-Fi-Profil" auf Ihrem Gerät herunterzuladen und das Surfen zu genießen.',
			connect_btn: 'Jetzt verbinden',
			connect_howTo: 'Wie es geht',
			text_5: 'Brauchen Sie mehr Details? Finden Sie heraus, wie es funktioniert!',
		},
		how_to:{
			intro:`Um dich mit den WLAN-Netzwerken zu verbinden, musst du ein 'WLAN-Profil' auf dein Gerät herunterladen und das Surfen genießen.`,
			title_1:`So lädst du dein Profil herunter`,
			title_2:`So installierst du dein WLAN-Konto-Profil`,
		},
		footer:{
			privacyPolicy:`<a class='font-source_regular text-white hover:text-gray-400' href="https://wballiance.com/openroaming/privacy-policy/" target="_blank" data-extlink rel="noreferrer">Datenschutzerklärung</a>`,
			termsAndConditions:`<a class='font-source_regular text-white hover:text-gray-400' href="https://wballiance.com/openroaming/toc/" target="_blank" data-extlink rel="noreferrer">Nutzungsbedingungen des Dienstes</a>`,
		}
	}
}