export const locale = {
	lang: 'es',
	data: {
		languageSelector: {
			selectLanguage: 'Seleccionar Idioma'
		},
		accessibility: {
			resetTitle: 'Reiniciar',
			closeTitle: 'Cerrar',
			menuTitle: 'Opciones de accesibilidad',
			increaseText: 'Aumentar el tamaño de texto',
			decreaseText: 'Reducir el tamaño de texto',
			increaseTextSpacing: 'Aumentar la distancia entre las líneas de texto',
			decreaseTextSpacing: 'Reducir la distancia entre las líneas de texto',
			increaseLineHeight: 'Aumentar la altura de las líneas de texto',
			decreaseLineHeight: 'Reducir la altura de las líneas de texto',
			invertColors: 'Invertir los colores',
			grayHues: 'Tono gris',
			underlineLinks: 'Subrayar enlaces',
			bigCursor: 'Puntero grande',
			readingGuide: 'Guía de lectura',
			textToSpeech: 'Texto a voz',
			speechToText: 'Voz a texto',
			disableAnimations: 'Deshabilitar animaciones',
			hotkeyPrefix: 'Hotkey:'
		},
		pageNotFound: {
			title: 'Página no encontrada',
			subtitle: 'Lo siento, pero la página que intentaba ver no existe.',
			action: 'Ir a la página de inicio'
		},
		error: {
			title: 'Error',
			subtitle: 'Lo siento, pero algo salió mal.',
			action: 'Ir a la página de inicio',
			messages: {
				generic: 'Se ha producido un error'
			}
		},
		home: {
			title: 'Bienvenido',
			downloadProfile: 'Descarga tu perfil Wi-Fi',
			email: {
				description: "Si lo desea, puede proporcionar su dirección de correo electrónico para recibir todas las instrucciones.",
				placeholder: "ejemplo@dominio.com",
			},
			privacy: {
				one: 'He leído y entendido',
				two: 'la Política de Privacidad',
				three: 'y las',
				four: 'Condiciones del Servicio',
				total:`He leído y entendido <a class='font-source_regular text-text-blue' href="https://www.guglielmo.biz/es/politica-de-privacidad/" target="_blank" data-extlink rel="noreferrer">la Política de Privacidad</a> y las <a class='font-source_regular text-text-blue' href="https://www.guglielmo.biz/es/aviso-legal/" target="_blank" data-extlink rel="noreferrer">Condiciones del Servicio</a>`,
			},
			requiredFields:	"Los campos obligatorios están marcados con un asterisco (*)",
			error: {
				emailFormat: "Formato de correo electrónico no válido",
			}
		},
		instructions:{
			title:"Conecte su dispositivo",
			checkOS: "Su sistema operativo es: ",
			changeOS:"¿No es correcto? Puede cambiarlo desde aquí",
			noOs: "No se pudo identificar su sistema operativo",
			ios:{
				welcome_step1:`Haz clic en el botón de abajo <span class='font-source_regular text-text-blue'>"Descarga tu perfil Wi-Fi"</span> para descargar el perfil Wi-Fi y haz clic en <span class='font-source_regular text-text-blue'>"Continuar"</span> en la ventana que aparece`,
				welcome_step2:`Haz clic en <span class='font-source_regular text-text-blue'>"Aceptar"</span> en la pantalla que aparece`,
				welcome_step3:`Haz clic en <span class='font-source_regular text-text-blue'>"Cerrar"</span> en la pantalla que aparece`,
				welcome_step4:`Una vez completada la descarga, será redirigido a la página con instrucciones para instalar su perfil de Wi-Fi.`,
			},
			android:{
				welcome_step1:`Haz clic en el botón de abajo <span class='font-source_regular text-text-blue'>"Descargar tu perfil Wi-Fi"</span> para descargar el perfil Wi-Fi y haz clic en <span class='font-source_regular text-text-blue'>"Instalar"</span> en la ventana que aparece.`,
			},
			mac: {
				welcome_step1: `Haz clic en el botón de abajo <span class='font-source_regular text-text-blue'>"Descargar tu perfil Wi-Fi"</span> para descargar el perfil Wi-Fi`,
			},

		},
		install_instructions:{
			title:"Cómo instalar tu perfil de cuenta Wi-Fi",
			ios:{
				install_step1: `Abre la aplicación <span class='font-source_regular text-text-blue'>"Configuración"</span> y haz clic en <span class='font-source_regular text-text-blue'>"Perfil descargado"</span>`,
				install_step2: `Haz clic en <span class='font-source_regular text-text-blue'>"Instalar"</span> en la esquina superior derecha e introduce el código de desbloqueo de tu dispositivo`,
				install_step3: `Haz clic en <span class='font-source_regular text-text-blue'>"Instalar"</span> en la ventana que aparece abajo para confirmar la instalación`,
				install_step4: `Después de instalar el perfil de tu cuenta Wi-Fi, haz clic en <span class='font-source_regular text-text-blue'>"Hecho"</span>. Verás que tu perfil se ha instalado correctamente en la sección <span class='font-source_regular text-text-blue'>"VPN y gestión de dispositivos"</span>`,
				install_step5: `Ahora es posible buscar el nombre de la red Wi-Fi y acceder de forma segura`,
			},
			android:{
				install_step1: `Haz clic en <span class='font-source_regular text-text-blue'>"Hecho"</span> en la ventana que aparece abajo para confirmar la instalación`,
				install_step2: `Ahora puedes buscar el nombre de la red Wi-Fi y conectarte de forma segura`,
			},
			mac: {
				install_step1: `Haz clic en el archivo descargado, abre la aplicación <span class='font-source_regular text-text-blue'>"Configuración"</span> y haz clic en <span class='font-source_regular text-text-blue'>"Privacidad y Seguridad"</span>`,
				install_step2: `Haz clic en <span class='font-source_regular text-text-blue'>"Perfil"</span>`,
				install_step3: `Haz doble clic en el perfil y luego en <span class='font-source_regular text-text-blue'>"Instalar"</span>`,
				install_step4: `Si se te solicita, ingresa tu contraseña personal y haz clic en <span class='font-source_regular text-text-blue'>"OK"</span>`,
				install_step5: `Ahora puedes buscar el nombre de la red Wi-Fi y conectarte de forma segura`,
			},

		},
		footer:{
			privacyPolicy:`<a class='font-source_regular text-white hover:text-gray-400' href="https://www.guglielmo.biz/en/privacy-cookies-en/" target="_blank" data-extlink rel="noreferrer">Política de Privacidad</a>`,
			termsAndConditions:`<a class='font-source_regular text-white hover:text-gray-400' href="https://www.guglielmo.biz/en/legal-notes/" target="_blank" data-extlink rel="noreferrer">Condiciones del Servicio</a>`,
		}
	}
}