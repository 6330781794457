import { JsonConvert, JsonConverter, JsonCustomConvert, JsonProperty } from "json2typescript";
import { BaseResponse } from "./base-response";

@JsonConverter
export class BooleanResponseConverter implements JsonCustomConvert<BooleanResponse>{
	serialize() { }
	deserialize(data: any): BooleanResponse {
		const jsonConvert: JsonConvert = new JsonConvert();
		return jsonConvert.deserializeObject(data, BooleanResponse);
	}
}

export class BooleanResponse extends BaseResponse {
	@JsonProperty("data", Boolean, true)
	data: boolean = false;	
}
