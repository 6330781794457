@for (item of accordionItems; track item.title; let idx = $index){
<div class="mt-2">
    <button class="w-full bg-gray-200 text-left px-4 py-2 focus:outline-none focus:bg-gray-300 rounded-xl mb-4 h-12"
        (click)="toggleAccordion(idx)">
        {{ item.title }}
    </button>
    @if(isOpen(idx)){
    <div class="overflow-hidden transition-[max-height] duration-300 bg-gray-100 px-4 py-2">
        @if(isOpen(idx)){
            <ng-content></ng-content>
        }
    </div>
    }
</div>
}