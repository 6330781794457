import { AfterViewInit, ChangeDetectorRef, Component } from '@angular/core';
import { LanguageService } from './core/services/internal/language.service';
import { TranslationService } from './core/services/internal/translation.service';
import { Helpers } from './core/helpers';
import { locale as enLang } from './core/config/i18n/en';
import { locale as itLang } from './core/config/i18n/it';
import { locale as deLang } from './core/config/i18n/de';
import { locale as esLang } from './core/config/i18n/es';
import { locale as frLang } from './core/config/i18n/fr';
import { locale as ptLang } from './core/config/i18n/pt';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.less'],
})
export class AppComponent implements AfterViewInit {
	constructor(
		private translationService: TranslationService,
		private chRef: ChangeDetectorRef
	) {}

	ngAfterViewInit(): void {
		const userLang = Helpers.getLanguage() || navigator.language.substring(0, 2).toLowerCase();
		this.setLanguage(userLang);
		this.registerTranslations();
		this.chRef.detectChanges();
	}

	private setLanguage(lang: string): void {
		if (!Helpers.getLanguage()) {
			Helpers.setLanguage(lang);
		}
		this.translationService.setLanguage(lang);
	}

	private registerTranslations(): void {
		this.translationService.loadTranslations(
			enLang,
			itLang,
			deLang,
			esLang,
			frLang,
			ptLang
		);
	}
}
