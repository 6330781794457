import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
	{ path: 'page-not-found', loadChildren: () => import('./pages/page-not-found/page-not-found.module').then(m => m.PageNotFoundModule) },
	{ path: 'home', loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule) },
	{ path: 'landing', loadChildren: () => import('./pages/landing/landing.module').then(m => m.LandingModule) },
	{ path: 'how-to', loadChildren: () => import('./pages/how-to/how-to.module').then(m => m.HowToModule) },
	{ path: 'how-to/:deviceOs', loadChildren: () => import('./pages/how-to/how-to.module').then(m => m.HowToModule) },
	{ path: 'how-to-install/:deviceOs', loadChildren: () => import('./pages/how-to-install/how-to-install.module').then(m => m.HowToInstallModule) },
	{ path: 'faqs', loadChildren: () => import('./pages/faqs/faqs.module').then(m => m.FaqsModule) },
	{ path: 'contact-us', loadChildren: () => import('./pages/contact-us/contact-us.module').then(m => m.ContactUsModule) },
	{ path: 'error', loadChildren: () => import('./pages/error/error.module').then(m => m.ErrorModule) },
	{ path: 'error/:errCode', loadChildren: () => import('./pages/error/error.module').then(m => m.ErrorModule) },
	{
		path: '',
		pathMatch: 'full',
		redirectTo: 'landing'
	},
	{
		path: '**',
		pathMatch: 'full',
		redirectTo: 'page-not-found'
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule { }
