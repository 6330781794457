import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-card',
	templateUrl: './card.component.html',
	styleUrl: './card.component.less'
})
export class CardComponent {
	@Input() title: string = "";

}
