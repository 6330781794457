export const locale = {
	lang: 'it',
	data: {
		languageSelector: {
			selectLanguage: 'Seleziona la lingua'
		},
		accessibility: {
			resetTitle: "Ripristina",
			closeTitle: "Chiudi",
			menuTitle: "Opzioni di accessibilità",
			increaseText: "Aumenta il tipo di carattere",
			decreaseText: "Diminuisci il tipo di carattere",
			increaseTextSpacing: "Aumenta lo spazio tra le righe di testo",
			decreaseTextSpacing: "Diminuisci lo spazio tra le righe di testo",
			increaseLineHeight: "Aumenta l'altezza della linea",
			decreaseLineHeight: "Diminuisci l'altezza della linea",
			invertColors: "Inverti i colori",
			grayHues: "Toni di grigio",
			underlineLinks: "Sottolinea i link",
			bigCursor: "Puntatore grande",
			readingGuide: "Guida di lettura",
			textToSpeech: "Testo a voce",
			speechToText: "Voce a testo",
			disableAnimations: "Disabilita le animazioni",
			hotkeyPrefix: "Hotkey:",
		},
		pageNotFound: {
			title: "Pagina non trovata",
			subtitle: "Spiacenti, ma la pagina che stavi cercando di visualizzare non esiste.",
			action: "Vai alla homepage",
		},
		error: {
			title: "Errore",
			subtitle: "Spiacenti, ma qualcosa è andato storto.",
			action: "Vai alla homepage",
			messages: {
				generic: "Si è verificato un errore",
			}
		},
		home: {
			title: "Benvenuto",
			downloadProfile: "Scarica il tuo profilo Wi-Fi",
			email: {
				description: "Se vuoi, puoi fornire il tuo indirizzo email per ricevere tutte le istruzioni.",
				placeholder: "esempio@dominio.com",
			},
			privacy: {
				one:'Ho letto e compreso',
				two:'l\'Informativa Privacy',
				three:'e i',
				four:'Termini e Condizioni del servizio',
				total:`Ho letto e compreso <a class='font-source_regular text-text-blue' href="https://www.guglielmo.biz/it/privacy-cookies/" target="_blank" data-extlink rel="noreferrer">l'Informativa Privacy</a> e i <a class='font-source_regular text-text-blue' href="https://www.guglielmo.biz/it/note-legali/" target="_blank" data-extlink rel="noreferrer">Termini e Condizioni del servizio</a>`,
			},
			requiredFields: "I campi obbligatori sono contrassegnati con un asterisco (*)",
			error: {
				emailFormat: "Formato email non valido",
			}
		},
		instructions:{
			title:"Connetti il tuo dispositivo",
			checkOS: "Il tuo Sistema Operativo è: ",
			changeOS:"Non è corretto? Puoi cambiarlo da qui",
			noOS:"Errore nel rilevare il Sistema Operativo",
			ios:{
				welcome_step1:`Clicca sul tasto in basso<span class='font-source_regular text-text-blue'>"Scarica il tuo profilo Wi-Fi"</span> per scaricare il profilo Wi-Fi e premi su <span class='font-source_regular text-text-blue'>"Continua"</span>nella finestra che appare`,
				welcome_step2:`Clicca <span class='font-source_regular text-text-blue'>"Accetta"</span> nella schermata che appare`,
				welcome_step3:`Clicca <span class='font-source_regular text-text-blue'>"Chiudi"</span> nella schermata che appare`,
				welcome_step4:`Una volta completato il download, verrai reindirizzato alla pagina con le istruzioni per installare il tuo profilo Wi-Fi.`,
			},
			android:{
				welcome_step1:`Clicca sul tasto in basso<span class='font-source_regular text-text-blue'>"Scarica il tuo profilo Wi-Fi"</span> per scaricare il profilo Wi-Fi e premi su <span class='font-source_regular text-text-blue'>"Installa"</span>nella finestra che appare`,
			},
			mac:{
				welcome_step1:`Clicca sul tasto in basso<span class='font-source_regular text-text-blue'>"Scarica il tuo profilo Wi-Fi"</span> per scaricare il profilo Wi-Fi`,
			},
		},
		install_instructions:{
			title:"Come installare il tuo profilo account Wi-Fi",
			ios:{
				install_step1:`Apri l'applicazione <span class='font-source_regular text-text-blue'>"Impostazioni"</span> e clicca su <span class='font-source_regular text-text-blue'>"Profilo Scaricato"</span>`,
				install_step2:`Clicca su <span class='font-source_regular text-text-blue'>"Installare"</span> nell'angolo in alto a destra ed inserisci il codice di sblocco del tuo dispositivo`,
				install_step3:`Clicca su <span class='font-source_regular text-text-blue'>"Installare"</span> nella finesta che appare in basso per confermare l'installazione`,
				install_step4:`Dopo aver installato il profilo del tuo account Wi-Fi, fai clic su <span class='font-source_regular text-text-blue'>"Fine"</span>. Vedrai quindi che il tuo profilo è stato installato correttamente nella sezione <span class='font-source_regular text-text-blue'>"VPN e gestione dispositivi"</span>`,
				install_step5:`Ora è possibile cercare il nome della rete Wi-Fi e accedere in sicurezza`,
			},
			android:{
				install_step1:`Clicca su <span class='font-source_regular text-text-blue'>"Fine"</span> nella finesta che appare in basso per confermare l'installazione`,
				install_step2:`Ora è possibile cercare il nome della rete Wi-Fi e accedere in sicurezza`,
			},
			mac:{
				install_step1:`Clicca sul file scaricato e apri l'applicazione <span class='font-source_regular text-text-blue'>"Impostazioni"</span> e clicca su <span class='font-source_regular text-text-blue'>"Privacy & Sicurezza"</span>`,
				install_step2:`Clicca su <span class='font-source_regular text-text-blue'>"Profilo"</span>`,
				install_step3:`Doppio click sul profilo e poi sul <span class='font-source_regular text-text-blue'>"Install"</span>`,
				install_step4:`Se richiesto, inserisci la tua password personale e clicca su <span class='font-source_regular text-text-blue'>"OK"</span>`,
				install_step5:`Ora è possibile cercare il nome della rete Wi-Fi e accedere in sicurezza`,
			},
		},
		footer:{
			privacyPolicy:`<a class='font-source_regular text-white hover:text-gray-400' href="https://www.guglielmo.biz/it/privacy-cookies/" target="_blank" data-extlink rel="noreferrer">Privacy Policy</a>`,
			termsAndConditions:`<a class='font-source_regular text-white hover:text-gray-400' href="https://www.guglielmo.biz/it/note-legali/" target="_blank" data-extlink rel="noreferrer">Termini e Condizioni</a>`,
		}
	}
}