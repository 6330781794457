import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Helpers } from '../../helpers';

@Injectable({
	providedIn: 'root'
})
export class LanguageService {
	/** Selected language upadted */
	language$: BehaviorSubject<string> = new BehaviorSubject<string>("");

	constructor() {
	}

	/** Emit new language update event */
	update(language: string) {
		Helpers.setHtmlLangAttribute(language);
		this.language$.next(language);
	}
}
