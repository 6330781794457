export const locale = {
	lang: 'fr',
	data: {
		languageSelector: {
			selectLanguage: 'Choisir la langue'
		},
		accessibility: {
			resetTitle: 'Réinitialiser',
			closeTitle: 'Fermer',
			menuTitle: 'Options d\'accessibilité',
			increaseText: 'Augmenter la taille du texte',
			decreaseText: 'Diminuer la taille du texte',
			increaseTextSpacing: 'Augmenter l\'espace entre les lignes de texte',
			decreaseTextSpacing: 'Diminuer l\'espace entre les lignes de texte',
			increaseLineHeight: 'Augmenter la hauteur des lignes de texte',
			decreaseLineHeight: 'Diminuer la hauteur des lignes de texte',
			invertColors: 'Inverser les couleurs',
			grayHues: 'Couleurs grises',
			underlineLinks: 'Soulignage des liens',
			bigCursor: 'Pointeur clair',
			readingGuide: 'Ligne de lecture',
			textToSpeech: 'Texte à voix',
			speechToText: 'Voix à texte',
			disableAnimations: 'Désactiver les animations',
			hotkeyPrefix: 'Hotkey:'
		},
		pageNotFound: {
			title: 'Page non trouvée',
			subtitle: 'Désolé, mais la page que vous avez essayé de voir n\'existe pas.',
			action: 'Aller à la page d\'accueil'
		},
		error: {
			title: 'Erreur',
			subtitle: 'Désolé, mais quelque chose s\'est mal passé.',
			action: 'Aller à la page d\'accueil',
			messages: {
				generic: 'Une erreur s\'est produite'
			}
		},
		home: {
			title: 'Bienvenue',
			downloadProfile: 'Téléchargez votre profil Wi-Fi',
			email: {
				description: "Si vous le souhaitez, vous pouvez fournir votre adresse e-mail pour recevoir toutes les instructions.",
				placeholder: "exemple@domaine.fr",
			},
			privacy: {
				one: 'J\'ai lu et compris',
				two: 'la Politique de confidentialité',
				three: 'et les',
				four: 'Conditions d\'utilisation du service',
				total:`J'ai lu et compris <a class='font-source_regular text-text-blue' href="https://www.guglielmo.biz/fr/politique-de-confidentialite/" target="_blank" data-extlink rel="noreferrer">la Politique de confidentialité</a> et les <a class='font-source_regular text-text-blue' href="https://www.guglielmo.biz/fr/mentions-legales/" target="_blank" data-extlink rel="noreferrer">Conditions d'utilisation du service</a>`,
			},
			requiredFields: "Les champs obligatoires sont marqués d'un astérisque (*)",
			error: {
				emailFormat: "Format d'e-mail invalide",
			}
		},
		instructions:{
			title:"Connectez votre appareil",
			checkOS: "Votre système d'exploitation est : ",
			changeOS:"Ce n'est pas correct ? Vous pouvez le changer",
			noOs: "Impossible d'identifier votre système d'exploitation",
			ios:{
				welcome_step1:`Cliquez sur le bouton ci-dessous<span class='font-source_regular text-text-blue'>"Téléchargez votre profil Wi-Fi"</span> pour télécharger le profil Wi-Fi et cliquez sur <span class='font-source_regular text-text-blue'>"Continuer"</span> dans la fenêtre qui apparaît`,
				welcome_step2:`Cliquez sur <span class='font-source_regular text-text-blue'>"Accepter"</span> sur l'écran qui apparaît`,
				welcome_step3:`Cliquez sur <span class='font-source_regular text-text-blue'>"Fermer"</span> sur l'écran qui apparaît`,
				welcome_step4:`Une fois le téléchargement terminé, vous serez redirigé vers la page contenant les instructions d'installation de votre profil Wi-Fi.`,
			},
			android:{
				welcome_step1:`Cliquez sur le bouton ci-dessous <span class='font-source_regular text-text-blue'>"Télécharger votre profil Wi-Fi"</span> pour télécharger le profil Wi-Fi et cliquez sur <span class='font-source_regular text-text-blue'>"Installer"</span> dans la fenêtre qui s'affiche`,
			},
			mac: {
				welcome_step1: `Cliquez sur le bouton ci-dessous <span class='font-source_regular text-text-blue'>"Télécharger votre profil Wi-Fi"</span> pour télécharger le profil Wi-Fi`,
			},

		},
		install_instructions:{
			title:"Comment installer votre profil de compte Wi-Fi",
			ios:{
				install_step1: `Ouvrez l'application <span class='font-source_regular text-text-blue'>"Réglages"</span> et cliquez sur <span class='font-source_regular text-text-blue'>"Profil téléchargé"</span>`,
				install_step2: `Cliquez sur <span class='font-source_regular text-text-blue'>"Installer"</span> dans le coin supérieur droit et entrez le code de déverrouillage de votre appareil`,
				install_step3: `Cliquez sur <span class='font-source_regular text-text-blue'>"Installer"</span> dans la fenêtre qui apparaît en bas pour confirmer l'installation`,
				install_step4: `Après avoir installé le profil de votre compte Wi-Fi, cliquez sur <span class='font-source_regular text-text-blue'>"Terminé"</span>. Vous verrez que votre profil a été correctement installé dans la section <span class='font-source_regular text-text-blue'>"VPN et gestion de l'appareil"</span>`,
				install_step5: `Il est maintenant possible de rechercher le nom du réseau Wi-Fi et de se connecter en toute sécurité`,
			},
			android:{
				install_step1: `Cliquez sur <span class='font-source_regular text-text-blue'>"Terminé"</span> dans la fenêtre qui apparaît en bas pour confirmer l'installation`,
				install_step2: `Vous pouvez maintenant rechercher le nom du réseau Wi-Fi et vous connecter en toute sécurité`,
			},
			mac: {
				install_step1: `Cliquez sur le fichier téléchargé, ouvrez l'application <span class='font-source_regular text-text-blue'>"Réglages"</span> et cliquez sur <span class='font-source_regular text-text-blue'>"Confidentialité & Sécurité"</span>`,
				install_step2: `Cliquez sur <span class='font-source_regular text-text-blue'>"Profil"</span>`,
				install_step3: `Double-cliquez sur le profil, puis cliquez sur <span class='font-source_regular text-text-blue'>"Installer"</span>`,
				install_step4: `Si demandé, entrez votre mot de passe personnel et cliquez sur <span class='font-source_regular text-text-blue'>"OK"</span>`,
				install_step5: `Vous pouvez maintenant rechercher le nom du réseau Wi-Fi et vous connecter en toute sécurité`,
			},

		},
		footer:{
			privacyPolicy:`<a class='font-source_regular text-white hover:text-gray-400' href="https://www.guglielmo.biz/en/privacy-cookies-en/" target="_blank" data-extlink rel="noreferrer">Politique de confidentialité</a>`,
			termsAndConditions:`<a class='font-source_regular text-white hover:text-gray-400' href="https://www.guglielmo.biz/en/legal-notes/" target="_blank" data-extlink rel="noreferrer">Conditions d'utilisation du service</a>`,
		}
	}
}