import { Device } from "../models/device.enum";

export class Helpers {

	/** Save current language in local storage */
	static setLanguage(userLang: string) {
		return localStorage.setItem('language', userLang);
	}

	/** Get current language */
	static getLanguage() {
		return localStorage.getItem('language') || navigator.language.substring(0, 2).toLowerCase();
	}

	/**
	 * Create cookie
	 * @param name
	 * @param value
	 * @param dayExpire
	 */
	static createCookie(name: string, value: string, dayExpire: number) {
		const today = new Date();
		today.setDate(today.getDate() + dayExpire);
		document.cookie = `${name}=${value}; expires=${today.toUTCString()}`;
	}

	/**
	 * Get cookie
	 * @param name
	 * @returns
	 */
	static getCookie(name: string) {
		const cookie = document.cookie.match(new RegExp(`${name}=[^;]+`));
		return cookie ? cookie[0].split('=')[1] : null;
	}

	/**
	 * Delete cookie
	 * @param name
	 */
	static deleteCookie(name: string) {
		const date = new Date(0);
		document.cookie = `${name}=${date.toUTCString()}; expires=${date.toUTCString()}`;
	}

	/**
	 * Get visitId from local storage
	 * @returns string
	 */
	static getVisitiIdFromLocalStorage() {
		return localStorage.getItem('visitId') || '';
	}

	/**
	 * Set visitId in local storage
	 * @returns string
	 */
	static setVisitiIdInLocalStorage(visitId: string) {
		localStorage.setItem('visitId', visitId);
	}

	/**
	 * Validate email
	 * @param email
	 * @returns boolean
	 */
	static validateEmail(email: string): boolean {
		const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return re.test(email);
	}

	/**
	 * Set html lang attribute
	 * @param lang
	 */
	static setHtmlLangAttribute(lang: string) {
		document.documentElement.lang = lang;
	}

	/**
	 * Returns the corresponding device OS enum value based on the provided string.
	 *
	 * @param os - A string representing the operating system.
	 * @returns A value from the Device enum corresponding to the provided OS string.
	 *
	 * The function maps the provided operating system string to a corresponding
	 * device OS enum value. If the provided string matches one of the known OS
	 * names ("Mac OS X", "iOS", "Android", "Windows"), it returns the corresponding
	 * enum value from the Device enum. If the provided string does not match any
	 * known OS names, it returns Device.UndefinedOS.
	 */
	static getDeviceOs(os: string) {
		switch (os) {
			case "Mac OS X":
				return Device.MacOS;
			case "iOS":
				return Device.iOS;
			case "Android":
				return Device.Android;
			case "Windows":
				return Device.Windows;
			default:
				return Device.UndefinedOS;
		}
	}
}