@if (deviceOs) {
	<section>
		<div class="flex flex-col gap-8">
			<!-- <h1 class=" text-center text-2xl font-source_extralight text-text-blue"> {{'instructions.title' | translate}}</h1> -->
			@switch (deviceOs) {
				@case (device.iOS) {
					<div>
						<div class="grid grid-cols-12 gap-4">
							<div>
								<span class="text-4xl text-gray-300">1.</span>
							</div>
							<div class="col-span-11">
								@if(_currentRoute == 'how-to'){
									<span class="text-xl font-source_extralight text-text-blue" [innerHTML]="'instructions.ios.welcome_step1_how_to' | translate"></span>
								}@else {
									<span class="text-xl font-source_extralight text-text-blue" [innerHTML]="'instructions.ios.welcome_step1' | translate"></span>
								}
								<!-- <span class="text-xl font-source_extralight text-text-blue" [innerHTML]="'instructions.ios.welcome_step1' | translate"></span> -->
							</div>
						</div>
						<div class="m-auto w-48">
							<img src="./assets/images/instructions/welcome/ios/welcome_step1.png" alt="">
						</div>
					</div>
					<div>
						<div class="grid grid-cols-12 gap-4">
							<div>
								<span class="text-4xl text-gray-300">2.</span>
							</div>
							<div class="col-span-11">
								<span class="text-xl font-source_extralight text-text-blue" [innerHTML]="'instructions.ios.welcome_step2' | translate"></span>
							</div>
						</div>
						<div class="m-auto w-48">
							<img src="./assets/images/instructions/welcome/ios/welcome_step2.png" alt="">
						</div>
					</div>
					<div>
						<div class="grid grid-cols-12 gap-4">
							<div>
								<span class="text-4xl text-gray-300">3.</span>
							</div>
							<div class="col-span-11">
								<span class="text-xl font-source_extralight text-text-blue" [innerHTML]="'instructions.ios.welcome_step3' | translate"></span>
							</div>
						</div>
						<div class="m-auto w-48">
							<img src="./assets/images/instructions/welcome/ios/welcome_step3.png" alt="">
						</div>
					</div>
				}
				@case (device.Android) {
					<div>
						<div class="grid grid-cols-12 gap-4">
							<div>
								<span class="text-4xl text-gray-300">1.</span>
							</div>
							<div class="col-span-11">
								<span class="text-xl font-source_extralight text-text-blue" [innerHTML]="'instructions.android.welcome_step1' | translate"></span>
							</div>
						</div>
						<div class="m-auto w-48">
							<img src="./assets/images/instructions/welcome/android/welcome_step1.png" alt="">
						</div>
					</div>
				}
				@case (device.MacOS) {
					<div>
						<div class="grid grid-cols-12 gap-4">
							<div>
								<span class="text-4xl text-gray-300">1.</span>
							</div>
							<div class="col-span-11">
								@if(_currentRoute == 'how-to'){
									<span class="text-xl font-source_extralight text-text-blue" [innerHTML]="'instructions.mac.welcome_step1_how_to' | translate"></span>
								}@else {
									<span class="text-xl font-source_extralight text-text-blue" [innerHTML]="'instructions.mac.welcome_step1' | translate"></span>
								}
							</div>
						</div>
						<!-- <div class="m-auto w-48">
							<img src="./assets/images/instructions/welcome/mac/welcome_step1.png" alt="">
						</div> -->
					</div>
				}
				@case (device.Windows) {
					<div>
						<div class="grid grid-cols-12 gap-4">
							<div>
								<span class="text-4xl text-gray-300">1.</span>
							</div>
							<div class="col-span-11">
								@if(_currentRoute == 'how-to'){
									<span class="text-xl font-source_extralight text-text-blue" [innerHTML]="'instructions.windows.welcome_step1_how_to' | translate"></span>
								}@else {
									<span class="text-xl font-source_extralight text-text-blue" [innerHTML]="'instructions.windows.welcome_step1' | translate"></span>
								}
							</div>
						</div>
					</div>
				}
				@default {
					<!-- Card 1 -->
					<div class="bg-white border border-gray-300 shadow-md overflow-hidden">
						<div class="p-6">
							<h3 class="text-2xl font-semibold mb-2">Titolo Card 1 default</h3>
							<p class="text-gray-700">Descrizione dettagliata della card 1. Questa descrizione spiega il contenuto
								della card in modo chiaro e conciso.</p>
						</div>
					</div>
					<!-- Card 2 -->
					<div class="bg-white border border-gray-300 shadow-md overflow-hidden">
						<div class="p-6">
							<h3 class="text-2xl font-semibold mb-2">Titolo Card 2 default</h3>
							<p class="text-gray-700">Descrizione dettagliata della card 2. Questa descrizione spiega il contenuto
								della card in modo chiaro e conciso.</p>
						</div>
					</div>
					<!-- Card 3 -->
					<div class="bg-white border border-gray-300 shadow-md overflow-hidden">
						<div class="p-6">
							<h3 class="text-2xl font-semibold mb-2">Titolo Card 3 default</h3>
							<p class="text-gray-700">Descrizione dettagliata della card 3. Questa descrizione spiega il contenuto
								della card in modo chiaro e conciso.</p>
						</div>
					</div>
				}
			}
		</div>
		<!-- <div class="mt-5">
			<span class="text-xl font-source_extralight text-text-blue" [innerHTML]="'instructions.ios.welcome_step4' | translate"></span>
		</div> -->
	</section>
}