import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class HttpUtilsService {

	constructor() { }

	/**
	 * Get standard header asynchronously
	 */
	getHTTPHeadersAsync(contentType?: string): Observable<any> {
		try {
			const header: any = {
				'Content-Type': contentType ? contentType : 'application/json',
			};

			return of(header);
		} catch (err) {
			console.error(err);
			return throwError(() => err);
		}
	}
}
