import { JsonConverter, JsonCustomConvert, JsonConvert, JsonObject, JsonProperty } from "json2typescript";

@JsonConverter
export class BaseResponseConverter implements JsonCustomConvert<BaseResponse>{
	serialize() { }
	deserialize(data: any): BaseResponse {
		const jsonConvert: JsonConvert = new JsonConvert();
		return jsonConvert.deserializeObject(data, BaseResponse);
	}
}

@JsonObject("BaseResponse")
/** Base response */
export class BaseResponse {
	@JsonProperty("success", Boolean, true)
	success: boolean = false;
	@JsonProperty("errorCode", Number, true)
	errorCode: number = 0;
	@JsonProperty("errorMessage", String, true)
	errorMessage: string = "";
	@JsonProperty("elapsed", Number, true)
	elapsed: number = 0;
}
